<script>
import Form from '../../../../../components/form';
import request from '../../../../../utils/request';

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [],
      formFunctionCode: 'edit_form',
      productSelect: [],
    };
  },
  async created() {
    // this.rule = await this.getFormRule('kms_on_product_form');
    // this.getProduct();
  },
  methods: {

    // 设置rule
    setRule(v) {
      const item = v;
      if (item.field === 'bsDirectSystemCode') {
        item.restful = '/kms/kmstenantrydirectcustomerorg/list';
        item.headers = { functionCode: 'supplier_list' };
        item.restfulParams = {
          enableStatus: '009',
        };
        item.optionsKey = { label: 'bsDirectSystemName', value: 'bsDirectSystemCode' };
        item.props = {
          ...item.props,
          filterable: true,
          remote: true,
          remoteParams: 'bsDirectSystemName',
        };
      }
      if (item.field === 'sellPartyCode') {
        item.restful = '/mdm/mdmCustomerMsgController/findCustomerSelectList';
        item.restfulParams = {
          enableStatus: '009',
        };
        item.props = {
          ...item.props,
          filterable: true,
          remote: true,
          remoteParams: 'customerName',
        };
        item.optionsKey = { label: 'customerName', value: 'customerCode' };
        item.refresh = true;
      }
      return item;
    },
    // 表单渲染完成后回调
    formComplete() {
      this.basisRule = this.rule;
      const directSystemId = this.getRule('bsDirectSystemCode');
      const sellPartyCode = this.getRule('sellPartyCode');

      // const graspingRulesStores = this.getRule('graspingRulesStores');
      // sellPartyCode.on.getLabel = (e) => {
      //   console.log(e);
      //   this.setValue({
      //     sellPartyName: e,
      //   });
      // };
      sellPartyCode.on.change = (e) => {
        // console.log(e);
        // console.log(sellPartyCode);
        const selectData = sellPartyCode.options.find((item) => item.customerCode === e);
        // console.log(selectData);
        this.setValue({
          sellPartyId: e,
          sellPartyName: selectData.customerName,
        });
      };
      directSystemId.on.change = (e) => {
        const selectData = directSystemId.options.find((item) => item.bsDirectSystemCode === e);
        console.log(selectData);
        this.bsDirectSystemId = selectData.bsDirectSystemId;
        this.bsDirectSystemCode = selectData.bsDirectSystemCode;
        this.setValue({
          bsDirectSystemId: selectData.bsDirectSystemId,
          bsDirectSystemName: selectData.bsDirectSystemName,
        });
        this.directSystemId = selectData.bsDirectSystemId;
      };
      if (this.formConfig.code === 'edit' || this.formConfig.code === 'view') {
        // this.disabled(true, ['directSystemId', 'terminalCode']);
        request.post('/kms/kmssupplier/query', {
          id: this.formConfig.row.id,
          directSystemId: this.formConfig.row.directSystemId,
          directSystemName: this.formConfig.row.directSystemName,
        }, { headers: { functionCode: 'supplier_list' } }).then((res) => {
          if (res.success) {
            console.log(res);
            this.setValue({
              ...res.result,
            });
          }
        });
      }
    },
    // 提交
    submit() {
      const formData = this.getFormData(); // 获取表单的值
      if (formData) {
        let url = '/kms/kmssupplier/save';
        const params = { ...formData };

        if (this.formConfig.code === 'edit') {
          url = '/kms/kmssupplier/update';
          params.id = this.formConfig.row.id;
        }

        // params.directProductReqVos = params.graspingRulesStores;
        // delete params.graspingRulesStores;

        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
</script>
